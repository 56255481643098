<template>
  <!-- 头部 -->
  <header class="header">
    <!-- 头部的第一行 -->
    <div class="top">
      <!-- 登录时的头部显示不同 -->
      <div
        class="container"
        v-if="$route.path == '/login'"
      >
        <div class="login-top">
          <p>万福家优商城 - 登录</p>
        </div>
      </div>

      <!-- 其他的都一样 -->
      <div
        class="container"
        v-else
      >
        <!-- 左侧首页 -->
        <div class="loginList">
          <router-link
            to="/home"
            class="logo"
          >
            <div class="homeBox">
              <img
                src="./images/home.png"
                alt=""
              >
              <span>首页</span>
            </div>
          </router-link>
          <!-- <p v-if="!userName">
            <span>请</span>
            <router-link to="/login">登录</router-link>
            <router-link
              to="/Register"
              class="register"
            >免费注册</router-link>
          </p>
          <p v-else>
            <a>{{ userName }}</a>
            <a @click="logOut">退出登录</a>
          </p> -->
        </div>
        <!-- 右侧导航 -->
        <div class="typeList">
          <span
            class="greeting"
            v-if="!userName"
          >
            <span>早上好，欢迎来到万福家优商城！</span>
            <router-link to="/login">请登录</router-link>
          </span>
          <span
            v-else
            class="greeting"
          >
            <span>早上好，欢迎来到万福家优商城！</span>
            <span>{{ userName }}</span>
            <a
              href="#"
              @click="logOut"
            >退出登录</a>
          </span>
          <router-link to="/center/myorder">我的订单</router-link>
          <!-- <a class="Focus">我的关注 <img
              src="./images/arrow_bottom.png"
              alt=""
            ></a>  <router-link to="/">商家支持</router-link>
          <router-link to="/">服务中心</router-link> -->
          <router-link to="/center/Personal" class="person" >
            <span @mouseenter="current=1" >
              个人中心
            </span>
            <!-- 用户登录信息 -->
<!--            :class="{ loginInfo_box: current == 1 }"-->
            <div  @mouseleave="current=-1" class="loginInfo_box" :style="{
              display:current == 1 ? '' : 'none'
            }">
              <!-- 登录状态 -->
              <div v-if="userName" class="top_info">
                <!-- <el-avatar
                  :size="50"
                  src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                >
                  {{ userName }}
                </el-avatar> -->
                <img src="./TypeNav/images/buddha.png" alt="" />
                <p style="margin-top: 10px">{{ userName }}</p>
              </div>
              <!-- 未登录状态 -->
              <div v-else class="top_info">
                <img src="./TypeNav/images/buddha.png" alt="" />
                <p>hi~欢迎来到万福家优商城</p>
              </div>
              <div class="btn">
                <el-button round v-if="userName" size="mini" type="primary" @click="logOut">退出登录</el-button>
                <el-button v-else round size="mini" type="primary" @click="$router.push('/login')">登录/注册</el-button>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!--头部第二行 搜索区域-->
    <!-- 登录页不同样式 -->
    <div
      v-if="$route.path == '/login'"
      class="login-bottom"
    >
      <h1 class="logoArea">
        <router-link
          to="/home"
          class="logo"
        >
          <img
            style="width: 200px"
            src="./images/wfjy.png"
            alt=""
          />
        </router-link>
      </h1>
      <!-- 返回商城首页 -->
      <router-link
        to="/home"
        class="logo-gohome"
      >
        <img
          src="./images/home.png"
          alt=""
        />
        <span>商城首页</span>
      </router-link>
    </div>
    <!-- 除登录外 -->
    <div
      class="bottom"
      v-else
    >
      <h1 class="logoArea">
        <router-link
          to="/home"
          class="logo"
          title=""
        >
          <img
            style="width: 163px ;height: 60px;"
            src="./images/wfjy.png"
            alt=""
          />
        </router-link>
      </h1>
      <!-- 搜索框 -->
      <div class="searchArea">
        <form
          action="###"
          class="searchForm"
        >
          <input
            type="text"
            id="autocomplete"
            class="input-error input-xxlarge"
            v-model="keyword"
            placeholder="打印机"
            @keyup.enter="goSearch"
          >
          </input>
          <!-- <img
            class="camera"
            src="./images/camera.png"
            alt=""
          /> -->
          <button
            class="sui-btn btn-xlarge btn-danger"
            type="button"
            @click="goSearch"
          >
            搜商品
          </button>
          <!-- <button
            class="search_shop"
            type="button"
          >
            搜店铺
          </button> -->
        </form>
        <div class="keyword">
          <div class="keyword-item">精装修</div>
          <div class="keyword-item">家装</div>
          <div class="keyword-item">工装</div>
          <div class="keyword-item">塑形</div>
          <div class="keyword-item">私教</div>
          <div class="keyword-item">猫粮</div>
          <div class="keyword-item">狗粮</div>
        </div>
      </div>
      <!-- 购物车按钮 -->
      <div class="shopCart">
        <div class="cartBox">
          <router-link to="/shopcart">
            <div class="cart_content">
              <img src="./images/cart2.png"></img>
              <span>购物车</span>
              <em class="badge">({{ count }})</em>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { getCartCount } from "@/api";
export default {
  name: "Header",
  data() {
    return {
      keyword: "",
      current: -1,
      count: 0,
    };
  },
  methods: {
    // 点击搜索
    goSearch() {
      if (!this.keyword) {
        this.keyword = "打印机";
      }
      this.$router.push({
        path: "/search",
        query: { goodsName: this.keyword },
      });
      //通知兄弟组件Header清除关键字
      this.$bus.$emit("clearCategory");
    },
    // 点击退出登录
    logOut() {
      //退出登录需要做的事情
      //1:需要发请求，通知服务器退出登录【清除一些数据：token】
      //2:清除项目当中的数据【userInfo、token】

      try {
        this.$store.dispatch("resUserLogout", {
          account: this.$store.state.user.account,
        });
        // 跳转到首页
        this.$router.push("/home");
      } catch (error) {
        console.log(error.message);
      }
    },
    async getCartCount() {
      await getCartCount().then((res) => {
        this.count = res.data.count;
      });
    },
  },
  computed: {
    ...mapState({
      userName: (state) => state.user.account,
    }),
    ...mapGetters(["cartCount"]),
  },
  mounted() {
    // 通过全局事件总线清除关键字
    this.$bus.$on("clearkeyword", () => {
      this.keyword = "";
    });
    // this.getCartCount();
    this.$bus.$on("CartCount", () => {
      this.getCartCount();
    });
  },
};
</script>

<style lang="less" scoped>
.header {
  .person {
    position: relative;
    .loginInfo_box {
      position: absolute;
      // 设置阴影
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      width: 200px;
      height: 250px;
      background-color: #fff;
      right: -70px;
      top: 30px;
      z-index: 999;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      .top_info {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          padding-top: 5px;
          font-size: 12px;
          color: #999;
          font-weight: 600;
          font-family: "思源黑体";
        }
      }

      .btn {
        .el-button {
          width: 150px;
        }
      }
    }
  }

  & > .top {
    background-color: #eaeaea;
    height: 30px;
    line-height: 30px;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1200px;
      margin: 0 auto;

      .loginList {
        // 取消下划线
        a:hover {
          text-decoration: none;
        }
        .homeBox {
          img {
            padding-right: 5px;
            vertical-align: text-top;
          }
          span {
            color: #999;
            font-weight: 600;
            font-size: 12px;
            font-family: "思源黑体";
          }
        }
        p {
          margin-right: 10px;

          .register {
            border-left: 1px solid #b3aeae;
            padding: 0 5px;
            margin-left: 5px;
          }
        }
      }

      .typeList {
        color: #999;
        font-weight: 600;
        font-size: 12px;
        font-family: "思源黑体";
        .greeting {
          a {
            color: #1C5BA1;
            border: 0;
          }
        }
        a {
          color: #9e9e9e;
          padding: 0 10px;
          border-left: 1px solid #b3aeae;
        }
        .Focus {
          cursor: pointer;
        }
      }

      // 登录时的顶部样式
      .login-top {
        margin: 0 auto;
        cursor: default;
        color: #666;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  // 登录页的样式
  & > .login-bottom {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logoArea {
      .logo {
        img {
          margin: 15px 25px;
        }
      }
    }
    .logo-gohome {
      margin: 15px 25px;
      cursor: pointer;
      color: #666;

      img {
        vertical-align: text-top;
        margin-right: 5px;
      }
      span {
        color: #666;
        font-weight: 500;
        font-size: 16px;
        font-family: "思源黑体";
      }
    }
    .logo-gohome:hover {
      text-decoration: none;
    }
  }

  // 除登录页的样式
  & > .bottom {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    align-items: flex-start;

    .searchArea {
      width: 600px;
      .searchForm {
        position: relative;
        display: flex;
        // justify-content: center;

        input {
          box-sizing: border-box;
          width: 400px;
          height: 34px;
          padding: 0px 40px 0 30px;
          border: 2px solid #1C5BA1;
          border-right: 0;
          background: url("./images/Zoom.png") no-repeat 8px center;
          &:focus {
            outline: none;
          }
        }
        .camera {
          width: 20px;
          height: 15px;
          position: absolute;
          right: 160px;
          top: 12px;
        }

        button {
          height: 34px;
          width: 80px;
          background-color: #1C5BA1;
          border: none;
          color: #fff;
          font-family: "思源黑体";
          font-size: 14px;
          cursor: pointer;

          &:focus {
            outline: none;
          }
        }
        .search_shop{
          background: #37BDE8;
          margin-left: 10px;
        }
      }
      .keyword{
        display: flex;
        padding: 7px 0;
        .keyword-item{
          color: #999;
          margin-right: 10px;
          cursor: pointer;
          &:hover{
            color: #1C5BA1;
          }
        }
      }
    }

    .shopCart {
      width: 160px;
      .cartBox {
        width: 140px;
        border: 1px solid #d3d3d3;
        // 取消全局a标签的下划线
        a:hover {
          text-decoration: none;
        }
        .cart_content {
          display: flex;
          padding: 7.5px;
          justify-content: space-evenly;
          align-items: center;
          color: #1C5BA1;
          font-weight: 600;
          font-size: 14px;
          font-family: "思源黑体";
          img {
            color: #1C5BA1;
            height: 15px;
            width: 15px;
          }
        }
      }
      .cartBox:hover {

        border: 1px solid #1C5BA1;
      }
    }
  }
}
</style>

// 用户信息相关的工具函数
// 手机号
// 对外暴露一个函数存储access
export const setAccount = (account) => {
    localStorage.setItem('account', account);
};
//对外暴露一个函数获得account
export const getAccount = () => {
    return localStorage.getItem('account');
};
// 对外暴露一个函数清空token
export const removeAccount = () => {
    return localStorage.removeItem('account');
};

// 购物车数量
export const setCartCount = (cartCount) => {
    localStorage.setItem('cartCount', cartCount);
};
//对外暴露一个函数获得account
export const getCartCount = () => {
    return localStorage.getItem('cartCount');
};
// 对外暴露一个函数清空token
export const removeCartCount = () => {
    return localStorage.removeItem('cartCount');
};
// 当前这个模块：API进行统一管理
// 引入封装的axios
import requests from "./request";
// 引入封装的mockaxios
import mockRequests from "./mockAjax";
//三级联动接口
// /api/product/getBasecategoryList get 无参数
//发请求：axios发请求返回结果Promise对象
// export const reqCategoryList = () => requests.get("/product/getBaseCategoryList");
//获取banner（Home首页轮播图接口）
export const reqGetBannerList = () => mockRequests.get("/banner");
//获取floor
export const reqGetFloorList = () => mockRequests.get("/floor");
// 当前这个函数需不需要接受外部传递参数
export const reqGetSearchInfo = (params) =>
    requests({
        url: "/list",
        method: "post",
        data: params, //当前这个接口，给服务器传递参数params，至少是一个空对象
    });
//获取产品详情信息的接口URL:/api/item/{skuId}请求方式：get
// export const reqGoodsInfo = (skuId) =>
//     requests({
//         url: `/item/${skuId}`,
//         method: "get",
//     });
//将产品添加到购物车中（获取更新某一个产品的个数）
// /api/cart/addToCart/{ skuId }/{ skuNum }
export const reqAddOrUpdateShopCart = (skuId, skuNum) =>
    requests({
        url: `/cart/addToCart/${skuId}/${skuNum}`,
        method: "post",
    });

//获取购物车列表数据接口
//URL:/api/cart/cartList method:get
export const reqCartList = () =>
    requests({
        url: "/cart/cartList",
        method: "get",
    });
//删除购物产品的接口
//URL:/api/cart/deleteCart/{skuId}   method:DELETE
export const reqDeleteCartById = (skuId) =>
    requests({
        url: `/cart/deleteCart/${skuId}`,
        method: "delete",
    });
//修改商品的选中状态
//URL:/api/cart/checkCart/{skuId}/{isChecked}   method:get
export const reqUpdateCheckedByid = (skuId, isChecked) =>
    requests({ url: `/cart/checkCart/${skuId}/${isChecked}`, method: "get" });
//获取验证码
//URL:/api/user/passport/sendCode/{phone}  method:get
export const reqGetCode = (phone) =>
    requests({ url: `/user/passport/sendCode/${phone}`, method: "get" });
//注册
//url:/api/user/passport/register  method:post    phone code password
export const reqUserRegister = (data) =>
    requests({ url: "/user/passport/register", data, method: "post" });

//获取用户信息【需要带着用户的token向服务器要用户信息】
//URL:/api/user/passport/auth/getUserInfo  method:get
// export const reqUserInfo = () =>
//     requests({ url: "/user/passport/auth/getUserInfo", method: "get" });
//退出登录
//URL:/api/user/passport/logout  get
export const reqlogout = () => requests({ url: "/user/passport/logout", method: "get" });
//获取用户地址信息
//URL:/api/user/userAddress/auth/findUserAddressList  method:get
export const reqAddressInfo = () =>
    requests({ url: "/user/userAddress/auth/findUserAddressList/", method: "get" });
//获取商品清单
//URL:/api/order/auth/trade   method:get
export const reqOrderInfo = () => requests({ url: "/order/auth/trade", method: "get" });
//提交订单的接口
//URL:/api/order/auth/submitOrder?tradeNo={tradeNo}  method:post
export const reqSubmitOrder = (tradeNo, data) => requests({ url: `/order/auth/submitOrder?tradeNo=${tradeNo}`, data, method: "post" });
//获取支付信息
//URL:/api/payment/weixin/createNative/{orderId}  GET
export const reqPayInfo = (orderId) => requests({ url: `/payment/weixin/createNative/${orderId}`, method: "get" });
//获取支付订单状态
//URL:/api/payment/weixin/queryPayStatus/{orderId}  get
export const reqPayStatus = (orderId) => requests({ url: `/payment/weixin/queryPayStatus/${orderId}`, method: 'get', nprogress: false });
//获取个人中心的数据
//api/order/auth/{page}/{limit}  get
export const reqMyOrderList = (page, limit) => requests({ url: `/order/auth/${page}/${limit}`, method: 'get' });



// 商品
// 获取首页热销商品列表
export const reqHotGoods = (data) => requests({ url: `/index/hot_goods`,data, method: 'post' });
// 获取首页商品分类列表
export const reqCategoryList = () => requests({ url: `/goods_category/tree`, method: 'post' });
// 获取商品列表
export const reqGetGoodsList = (data) => requests({ url: `/goods/goods_list`, data, method: 'post' });
// 获取商品总数
export const reqGetGoodsCount = (data) => requests({ url: `/goods/goods_count`, data, method: 'post' });
// 获取商品详情
export const reqGoodsInfo = (data) => requests({ url: `/goods/goods_detail`, data, method: 'post' });

//export const getGoodsSkuInfo = (data) => requests({ url: `http://120.79.157.155:9500/admin/goods/getGoodsSkuInfo`, data, method: 'post' });

export const getGoodsSkuInfo = (data) => requests({ url: `https://v3.api.8080bl.com/admin/goods/getGoodsSkuInfo`, data, method: 'post' });

// 收货地址
// 获取用户收货地址列表
export const reqUserAddressList = (data) => requests({ url: `/user/userInfo/list`, data, method: 'post' });
// 新增用户收货地址
export const reqAddUserAddress = (data) => requests({ url: `/user/userInfo/add`, data, method: 'post' });
// 编辑用户收货地址
export const reqEditUserAddress = (data) => requests({ url: `/user/userInfo/edit`, data, method: 'post' });
// 删除用户收货地址
export const reqDeleteUserAddress = (data) => requests({ url: `/user/userInfo/delete`, data, method: 'post' });
//设置默认收货地址
export const reqSetDefaultUserAddress = (data) => requests({ url: `/user/userInfo/setDefault`, data, method: 'post' });

// 购物车
// 获取用户购物车列表
export const reqUserCartList = (data) => requests({ url: `/user/userCart/list`, data, method: 'post' });
// 商品加入用户购物车
export const reqAddUserCart = (data) => requests({ url: `/user/userCart/add`, data, method: 'post' });
// 删除购物车商品
export const reqDeleteUserCart = (data) => requests({ url: `/user/userCart/delete`, data, method: 'post' });
// 编辑购物车商品
export const reqEditUserCart = (data) => requests({ url: `/user/userCart/edit`, data, method: 'post' });


// 用户
// 获取短信验证码
export const reqPhoneCode = (data) => requests({ url: "/sms/send", data, method: "post" });
//登录
export const reqUserLogin = (data) => requests({ url: "/user/login", data, method: "post" });
// 退出登录
export const reqUserLogout = (data) => requests({ url: "/user/loginOut", data, method: "post" });
// 获取用户信息
export const reqUserInfo = (data) => requests({ url: `/user/detail`, data, method: 'post' });
// 修改用户信息
export const reqEditUserInfo = (data) => requests({ url: `/user/edit`, data, method: 'post' });



// 订单
// 订单详情
export const reqMyOrder = (data) => requests({ url: `/order/my_order_list`, data, method: 'post' });
// 订单详情
export const reqOrderDetail = (data) => requests({ url: `/order/order_detail`, data, method: 'post' });
// 创建订单(购物车)
export const reqCreateOrder = (data) => requests({ url: `/order/create_order_by_cart`, data, method: 'post' });
// 创建订单（立即购买）
export const reqCreateOrderByGoods = (data) => requests({ url: `/order/create_order_by_goods`, data, method: 'post' });
// 确认收货
export const reqConfirmReceipt = (data) => requests({ url: `/order/confirm_receipt`, data, method: 'post' });
// 获取支付二维码
export const reqWxPayQRCode = (data) => requests({ url: `/api/wx-pay/native`, data, method: 'post' });
//获取购物车数量
export const getCartCount = (data) => requests({ url: `/user/userCart/count`, data, method: 'post' });

// 利用axios进行二次封装
import axios from 'axios';
import Vue from 'vue'
//引入进度条
import nprogress from 'nprogress';
// 引入进度条样式
import 'nprogress/nprogress.css';
//在当前模块中引入store
import store from '@/store';
import router from "../router";
//引入json-bigint
import JSONBIG from "json-bigint";
// 利用axios的creat方法创建一个axios实例，requests就是axios只不过要配置一下
const requests = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? '/apis' : '/apis',
    timeout: 5000, //代表超时时间5s
});

// 解决精度丢失
requests.defaults.transformResponse = [
    function (data) {
        const json = JSONBIG({
            storeAsString: true,
        });
        const res = json.parse(data);
        return res;
    },
];


//请求拦截器：在发请求之前，请求拦截器可以检测到，可以在请求发出去之前做一些事情
requests.interceptors.request.use((config) => {
    // config.headers = 'application/json'
    //config：配置对象，对象里面有一个属性很重要，headers请求头
    // if (store.state.detail.uuid_token) {
    //     //请求头添加一个字段(userTempId):和后台老师商量好了
    //     config.headers.userTempId = store.state.detail.uuid_token;
    // }
    //需要携带token带给服务器
    if (store.state.user.token) {
        config.headers.token = store.state.user.token;
    }
    if (!config.nprogress) return config;
    // 进度条开始
    nprogress.start();

    return config;
});

//相应拦截器
requests.interceptors.response.use(
    (res) => {
        console.log('res: ', res);
        if (res.data.code == 406 || res.data.code == 2004) {
            nprogress.done();
            let account = localStorage.getItem('account')
            if (account) {
                //清除token
                store.dispatch("resUserLogout", { account: account })
            } else {
                store.commit('CLEAR')
            }
            Vue.prototype.$notify.error({
                title: '错误',
                message: '登录账号已超过有效期，请重新登录'
            })
            nprogress.done();
            // 回到登录页
            return router.push("/login");
        }
        nprogress.done();
        return res.data; //成功的回调函数：服务器相应数据回来以后，响应拦截器可以检测到，可以做一些事情
    },
    (error) => {

        // 404请求失败
        if (error.response.status === 404) {
        }
        //token失效
        else if (error.response.status === 401) {
            console.log('error.response.status: ', error.response.status);
            //清除token
            store.dispatch("resUserLogout", { account: localStorage.getItem('account') }).then((data) => {
                console.log('data: ', data);
                // 回到登录页
                return router.push("/login");
            })
        }
        // document.getElementById('app').style.display = 'none'
        // Vue.prototype.$baseMessage(message || `后端接口未知异常`, 'error')

        return Promise.reject(error); //响应失败的回调函数 终止promise链
    }
);
export default requests;

import { reqAddressInfo, reqOrderInfo, reqMyOrder, reqOrderDetail, reqConfirmReceipt, reqCreateOrderByGoods, reqCreateOrder, reqWxPayQRCode } from "@/api";

const actions = {
    //获取用户地址信息
    async getUserAddress({ commit }) {
        let result = await reqAddressInfo();
        // console.log(result);
        if (result.code == 200) {
            commit("GETUSERADDRESS", result.data);
        }
    },
    //获取商品清单数据
    async getOrderInfo({ commit }) {
        let result = await reqOrderInfo();
        if (result.code == 200) {
            commit("GETORDERINFO", result.data);
        }
    },
    //获取我的订单列表
    async resMyOrder({ commit }, data) {
        let result = await reqMyOrder(data);
        return result
    },
    //获取订单详情
    async resOrderDetail({ commit }, data) {
        let result = await reqOrderDetail(data);
        return result
    },
    //创建订单(购物车)
    async resCreateOrder({ commit }, order_info) {
        let result = await reqCreateOrder(order_info);
        console.log('result: ', result);
        if (result.code == 200) {
            commit("CREATEORDER", result.data);
            return result
        }
    },
    //创建订单(商品详情)
    async resCreateOrderByGoods({ commit }, order_info) {
        let result = await reqCreateOrderByGoods(order_info);
        console.log('result: ', result);
        if (result.code == 200) {
            commit("CREATEORDER", result.data);
            return result
        }
    },
    //获取支付二维码
    async resWxPayQRCode({ commit }, order_info) {
        let result = await reqWxPayQRCode(order_info);
        console.log('result: ', result);
        return result
    },
    //确认收货
    async resConfirmReceipt({ commit }, order_info) {
        let result = await reqConfirmReceipt(order_info);
        console.log('result: ', result);
        return result
    },
};
const mutations = {
    GETUSERADDRESS(state, address) {
        state.address = address;
    },
    GETORDERINFO(state, orderInfo) {
        state.orderInfo = orderInfo;
    },
    CREATEORDER(state, orderInfo) {
        state.orderInfo = orderInfo;
    }
};
const state = {
    address: [],
    orderInfo: {},
};
const getters = {};
export default {
    actions,
    mutations,
    state,
    getters,
};
import { reqUserAddressList, reqAddUserAddress, reqEditUserAddress, reqSetDefaultUserAddress, reqDeleteUserAddress } from '@/api';
const state = {
    addressList: [],
};
const actions = {
    //获取用户地址列表
    async getUserAddressList({ commit }, account) {
        let result = await reqUserAddressList(account);
        console.log(result);
        if (result.code == 200) {
            commit('ADDRESSLIST', result.data.records);
            return result.data.records
        }
    },
    //新增用户地址列表
    async resAddUserAddress({ commit }, data) {
        let result = await reqAddUserAddress(data);
        console.log(result);
        if (result.code == 200) {
            return result;
        } else {
            return Promise.reject(new Error("faile"));
        }
    },
    //编辑用户地址列表
    async resEditUserAddress({ commit }, data) {
        let result = await reqEditUserAddress(data);
        console.log(result);
        if (result.code == 200) {
            return result;
        } else {
            return Promise.reject(new Error("faile"));
        }
    },
    //删除用户地址列表
    async resDeleteUserAddress({ commit }, data) {
        let result = await reqDeleteUserAddress(data);
        console.log(result);
        if (result.code == 200) {
            return result;
        } else {
            return Promise.reject(new Error("faile"));
        }
    },
    //设置默认地址
    async resSetDefaultUserAddress({ commit }, data) {
        let result = await reqSetDefaultUserAddress(data);
        console.log(result);
        if (result.code == 200) {
            return result;
        } else {
            return Promise.reject(new Error("faile"));
        }
    }
}
const mutations = {
    ADDRESSLIST(state, list) {
        state.addressList = list;
    }
}
const getters = {
    addressList(state) {
        return state.addressList || [];
    }
};
export default {
    state,
    actions,
    mutations,
    getters,
};
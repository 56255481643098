<template>
  <div class="columns">
    <div class="columns-box">
      <!-- 左 -->
      <div class="letf-box">
        <img src="./images/Headset.png" />
        <div>7×9小时在线客服</div>
        <p>Online Service</p>
      </div>
      <div class="line"></div>
      <!-- 中 -->
      <div class="middle-box">
        <img src="./images/Car.png" alt="" />
        <div>闪电发货</div>
        <p>Flash shipment</p>
      </div>
      <div class="line"></div>
      <!-- 右 -->
<!--      <div class="right-box">-->
<!--        <img src="./images/TencentCode.png" alt="" />-->
<!--        <div>八灵公众号</div>-->
<!--      </div>-->
    </div>
    <div class="line"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.columns {
  background: #e0e2e4;
  .columns-box {
    width: 1200px;
    height: 220px;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .letf-box {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 16px;
      color: #666;
      font-family: "思源黑体";
      cursor: default;
      div {
        padding-top: 20px;
        font-weight: 600;
      }
      p {
        margin-top: 5px;
        font-size: 14px;
      }
    }
    .middle-box {
      cursor: default;
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 16px;
      color: #666;
      font-family: "思源黑体";
      div {
        padding-top: 20px;
        font-weight: 600;
      }
      p {
        margin-top: 5px;
        font-size: 14px;
      }
    }
    .right-box {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 16px;
      color: #666;
      font-family: "思源黑体";
      cursor: default;

      div {
        padding-top: 20px;
        font-weight: 600;
      }
      img {
        border: 1px solid #c8c9ca;
      }
    }
    .line {
      height: 150px;
      width: 1px;
      background: url(./images/line.png) repeat;
    }
  }
  .line {
    height: 1px;
    background: #707070;
    width: 100%;
    opacity: 0.61;
  }
}
</style>

import {reqGetSearchInfo, reqGetGoodsList, reqGoodsInfo, reqGetGoodsCount, getGoodsSkuInfo} from "@/api"

// search模块的小仓库
const state = {
    // 仓库初始状态
    searchList: {},
    // 商品列表
    goodsList: [],
    // 商品总数
    goods_count: 0,
    // 商品详情
    goodsInfo: {}

}
const mutations = {
    GETSEARCHLIST(state, searchList) {
        state.searchList = searchList
    },
    // 获取商品列表
    GETGOODSLIST(state, goodsList) {
        state.goodsList = goodsList
    },
    // 获取商品总数
    GETGOODSCOUNT(state, goods_count) {
        state.goods_count = goods_count
    },
    // 获取商品详情
    GETGOODSINFO(state, goodsInfo) {
        state.goodsInfo = goodsInfo
    },
    // 获取商品详情(新)
    GETGOODSSKUINFO(state, goodsInfo) {
        state.goodsInfo = goodsInfo
    },
}
const actions = {
    async getSearchList({ commit }, params = {}) {
        let result = await reqGetSearchInfo(params)
        if (result.code = 200) {
            commit('GETSEARCHLIST', result.data)
        }
    },
    // 获取商品列表
    async getGetGoodsList({ commit }, params = {}) {
        let result = await reqGetGoodsList(params)
        if (result.code = 200) {
            commit('GETGOODSLIST', result.data)
            console.log('result.data.records: ', result.data);
            return result.data.records
        }
    },
    // 获取商品总数
    async resGetGoodsCount({ commit }, params = {}) {
        let result = await reqGetGoodsCount(params)
        if (result.code = 200) {
            commit('GETGOODSCOUNT', result.data)
            console.log('result.data.records: ', result.data);
            return result.data
        }
    },
    // 获取商品详情
    async resGoodsInfo({ commit }, id) {
        let result = await reqGoodsInfo(id)
        if (result.code = 200) {
            commit('GETGOODSINFO', result.data)
            return result
        } else {
            return Promise.reject(new Error("faile"));
        }
    },

    // 获取商品详情(新)
    async getGoodsSkuInfo({ commit }, id) {
        let result = await getGoodsSkuInfo(id)
        if (result.code = '00000') {
            commit('GETGOODSSKUINFO', result.data)
            return result
        } else {
            return Promise.reject(new Error("faile"));
        }
    },


}
//计算属性
//项目当中getters主要的作用是：简化仓库中的数据（简化数据而生）
//可以把我们将来在组件当中需要用的数据简化一下【将来组件在获取数据的时候就方便了】
const getters = {
    //当前形参state，当前仓库中的state，并非大仓库中的那个state
    // goodsList(state) {
    //     return state.searchList.goodsList || []
    // },
    attrsList(state) {
        return state.searchList.attrsList || []
    },
    trademarkList(state) {
        return state.searchList.trademarkList || []
    },
    goodsList(state) {
        return state.goodsList || []
    },
    goodsInfo(state) {
        return state.goodsInfo || []
    },
    goods_count(state) {
        return state.goods_count || []
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}

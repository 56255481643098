<template>
  <!-- 商品分类导航 -->
  <div class="type-nav">
    <div class="container">
      <div @mouseleave="leaveIndex" @mouseenter="enterShow">
        <div class="all" :class="{ hover_red: isHover }">全部商品分类</div>
        <transition name="sort">
          <div class="sort" v-show="show">
            <!-- 一级菜单 -->
            <div class="all-sort-list2" @click="goSearch">
              <div class="item bo" v-for="(c1, index) in categoryList" :key="c1.id"
                :class="{ cur: currentIndex == index }">
                <h3 @mouseenter="changeIndex(index)">
                  <a :data-categoryName1="c1.name" :data-categoryId1="c1.id">
                    {{ c1.name }}
                  </a>
                </h3>
                <!-- 二三级菜单 -->
                <div class="item-list clearfix" :style="{
                  display: currentIndex == index ? 'block' : 'none',
                  height: class_height + 'px',
                  background: '#fff',
                }" ref="itemList">
                  <div class="subitem" v-for="c2 in c1.children" :key="c2.id">
                    <dl class="fore">
                      <dt>
                        <a :data-categoryName1="c1.name" :data-categoryName2="c2.name" :data-categoryId2="c2.id"
                          :data-categoryId1="c1.id">{{ c2.name
                          }}</a>
                      </dt>
                      <dd>
                        <em v-for="c3 in c2.children" :key="c3.id">
                          <a :data-categoryName1="c1.name" :data-categoryName2="c2.name" :data-categoryName3="c3.name"
                            :data-categoryId3="c3.id" :data-categoryId2="c2.id" :data-categoryId1="c1.id">{{ c3.name
                            }}</a>
                        </em>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>

      <router-link to="/home" class="home" :class="{ active: $route.path == '/home' ? true : false }">首页</router-link>
      <!-- 左边图标 -->
      <i class="el-icon-arrow-left left_icon" @click="left_nav" v-show="isClick"></i>
      <nav class="nav" ref="nav">
        <a v-for="(c1, index) in categoryList" :key="c1.id" @click="goGoodsList(c1)"
          :class="{ active: c1.name == categoryName1 }">{{
            c1.name }}
        </a>
      </nav>
      <!-- 右边图标 -->
      <i class="el-icon-arrow-right right_icon" @click="right_nav" v-show='!isClick'></i>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
import throttle from "lodash/throttle";
export default {
  name: "TypeNav",
  data() {
    return {
      //存储用户鼠标移上哪一个一级分类
      currentIndex: -1,
      show: true,
      isHover: false,
      // 分类高度
      class_height: 495,
      // 顶部导航是否点击滑动
      isClick: false,
      categoryName1: ''
    };
  },
  //组件挂载完毕：可以向服务器发请求
  mounted() {
    // this.class_height = document.querySelector(".sort").offsetHeight;
    // console.log("this.class_height: ", this.class_height);
    //通知Vuex发请求，获取数据，存储于仓库当中
    this.$store.dispatch("categoryList"); //第一个参数是路径,第二个参数是传值
    //如果不是Home路由组件，将typeNav进行隐藏
    if (this.$route.path != "/home") {
      this.show = false;
    }
    // 鼠标滚轮事件监听器
    // this.$refs.nav.addEventListener("wheel", this.handleWheel);
  },
  watch: {
    '$route.query.category': {
      handler: function (newVlaue) {
        console.log('newVlaue: ', newVlaue);
        if (newVlaue) {
          let category = JSON.parse(newVlaue)
          console.log('category: ', category);
          this.categoryName1 = category[0].categoryname1
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState({
      //右侧需要的是一个函数，当使用这个计算属性的时候，右侧函数会立即执行一次
      //注入一个参数state，其实即为大仓库中的数据
      categoryList: (state) => state.home.categoryList,
      userName: (state) => state.user.account,
    }),
    // categoryname1: function () {
    //   return this.$route.query.category.categoryname1
    // }
  },
  methods: {
    // 点击退出登录
    logOut() {
      // this.$store.commit("CLEAR");
      //退出登录需要做的事情
      //1:需要发请求，通知服务器退出登录【清除一些数据：token】
      //2:清除项目当中的数据【userInfo、token】
      try {
        this.$store.dispatch("resUserLogout", {
          account: this.$store.state.user.account,
        });
        // 跳转到首页
        this.$router.push("/home");
      } catch (error) {
        console.log(error.message);
      }
    },
    // 左箭头点击事件
    left_nav() {
      // 获取nav的宽度
      const navWidth = this.$refs.nav.offsetWidth;
      this.$refs.nav.scrollLeft -= navWidth;
      this.isClick = false;
    },
    // 右箭头点击事件
    right_nav() {
      // 获取nav的宽度
      const navWidth = this.$refs.nav.offsetWidth;
      this.$refs.nav.scrollLeft += navWidth;
      this.isClick = true;
    },

    // 鼠标滚轮监听事件
    // handleWheel(e) {
    //   e.preventDefault(); // 阻止默认行为
    //   const delta = e.deltaY < 0 ? -1 : 1; // 判断滚轮方向
    //   this.$refs.nav.scrollLeft += delta * 50; // 修改滚动位置
    // },
    // 跳转商品列表
    goGoodsList(row) {
      console.log("row: ", row);
      let params = JSON.stringify([{ categoryname1: row.name, category: row.id }])
      this.$router.push({
        path: "/search",
        query: { category: params },
      });
    },
    // 分类导航栏
    // 鼠标离开修改响应式数据currentIndex属性 并且让商品分类列表进行隐藏
    leaveIndex() {
      this.isHover = false;
      // 离开后把当前的index变成-1
      this.currentIndex = -1;
      //让商品分类列表进行隐藏判断 如果是search路由组件的时候才会执行
      if (this.$route.path != "/home") {
        this.show = false;
      }
    },
    // // 鼠标进入修改响应式数据currentIndex属性
    changeIndex: throttle(function (index) {
      // console.log(index);
      // index：鼠标移上某一个一级分类的元素的索引值
      this.currentIndex = index;
    }, 50),

    //最好的解决方案：编程式导航+事件委派
    goSearch(e) {
      // 存在一些问题：事件委派，是把全部的子节点【h3、dt、d1、em】的事件委派给父亲节点点击a标签的时候，才会进行路由跳转【怎么能确定点击的一定是a标签】I
      //存在另外一个问题：即使你能确定点击的是a标签，如何区分是一级、二级、三级分类的标签。
      //第一个问题：把子节点当中a标签，我加上自定义属性data-categoryName，其余的子节点是没有的
      let element = e.target;
      //节点有一个属性dataset属性，可以获取节点的自定义属性与属性值 解构赋值
      console.log("element.dataset: ", element.dataset);
      if (!element.dataset.categoryname1) return
      let {
        categoryname1,
        categoryname2,
        categoryname3,
        categoryid1,
        categoryid2,
        categoryid3,
      } = element.dataset;
      //如果标签身上拥有categoryname一定是a标签
      //一级分类、二级分类、三级分类的a标签
      // 整理路由参数
      let location = { name: "search" };
      let category = [
        { categoryname1: categoryname1, category: categoryid1 },
        {
          categoryname2: categoryname2,
          category: categoryid2,
        },
        {
          categoryname3: categoryname3,
          category: categoryid3,
        },
      ];
      let query = {
        category: JSON.stringify(category),
      };
      // 合并路径和参数
      location.query = query;
      // 路由调转
      this.$router.push(location);
      // }
    },
    // 当鼠标移入的时候，让商品分类列表进行展示
    enterShow() {
      this.show = true;
      this.isHover = true;
    },
  },
};
</script>

<style lang="less" scoped>
.type-nav {
  border-bottom: 2px solid #1C5BA1;

  .container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    position: relative;

    .all {
      /* 阻止选中，包括文本和图片 */
      user-select: none;
      -webkit-user-select: none;
      width: 200px;
      height: 45px;
      background-color: #fff;
      line-height: 45px;
      text-align: center;
      color: #000;
      font-size: 18px;
      cursor: default;
      // font-family: "Courier New", Courier, monospace;
      font-family: "黑体";
      font-weight: 600;
    }

    .hover_red {
      color: #1C5BA1;
    }

    .all:hover {
      color: #1C5BA1;
    }

    .home {
      /* 阻止选中，包括文本和图片 */
      user-select: none;
      -webkit-user-select: none;
      width: 100px;
      height: 45px;
      font-size: 18px;
      color: #000;
      cursor: pointer;
      font-family: "黑体";
      font-weight: 600;
      text-align: center;
      line-height: 45px;
      text-decoration: none;
    }

    .home:hover {
      color: #1C5BA1;
    }

    .active {
      color: #1C5BA1 !important;
    }

    .nav {
      /* 阻止选中，包括文本和图片 */
      user-select: none;
      -webkit-user-select: none;
      overflow-x: auto;
      white-space: nowrap;
      width: 100%;
      text-overflow: clip;

      &::-webkit-scrollbar {
        display: none;
      }

      a {
        font-family: "黑体";
        font-weight: 600;
        height: 45px;
        margin-left: 18px;
        line-height: 45px;
        font-size: 18px;
        color: #333;
        text-decoration: none;
        cursor: pointer;
      }

      a:hover {
        color: #1C5BA1;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .sort {
      position: absolute;
      left: 0;
      top: 47px;
      width: 200px;
      // height: 461px;
      position: absolute;
      // background: #b8b8b8;
      background: #000;
      z-index: 999;

      .all-sort-list2 {
        opacity: 0.9;
        height: 496px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          display: none;
        }

        //overflow-y:hidden;
        .item {
          text-align: center;

          // cursor: pointer;
          h3 {
            line-height: 30px;
            font-size: 14px;
            font-weight: 600;
            overflow: hidden;
            padding: 3px 30px;
            margin: 0;
            cursor: default;
            color: #fff;
            // 鼠标样式
            cursor: pointer;

            a {
              color: #fff;
            }
          }

          .item-list {
            display: none;
            position: absolute;
            width: 734px;
            height: 496px;
            background: #fff;
            left: 200px;
            border: 1px solid #ddd;
            top: 0;
            z-index: 9999 !important;

            .subitem {
              float: left;
              width: 650px;
              padding: 0 4px 0 8px;
              font-size: 14px;

              dl {
                border-top: 1px solid #eee;
                padding: 6px 0;
                overflow: hidden;
                zoom: 1;

                &.fore {
                  border-top: 0;
                }

                dt {
                  float: left;
                  width: 80px;
                  line-height: 22px;
                  text-align: center;
                  padding: 3px 6px 0 0;
                  font-weight: 700;
                  cursor: pointer;
                }

                dt:hover {
                  color: #e1251b;
                }

                dd {
                  float: left;
                  width: 415px;
                  padding: 3px 0 0;
                  overflow: hidden;
                  cursor: pointer;

                  em {
                    float: left;
                    height: 14px;
                    line-height: 14px;
                    padding: 0 15px;
                    margin-top: 5px;
                    border-left: 1px solid #ccc;

                    a:hover {
                      color: #1C5BA1;
                      font-weight: 600;
                    }
                  }
                }
              }
            }
          }
        }

        .cur {
          background-color: #1C5BA1;
          color: #1C5BA1;
          opacity: 1;
          // background-color: skyblue;
        }
      }
    }

    //过渡动画的样式
    // 过渡动画开始状态（进入）
    .sort-enter {
      height: 0px;
    }

    //过渡动画结束状态（进入）
    .sort-enter-to {
      height: 580px;
    }

    //定义动画时间、速率
    .sort-enter-active {
      transition: all 1s linear;
    }
  }

  .left_icon {
    line-height: 43px;
    font-size: 20px;
    // 鼠标样式
    cursor: pointer;
    border: 1px solid #eee;
    background: #ddd;
  }

  .left_icon:hover {
    color: #e1251b;
  }

  .right_icon {
    line-height: 43px;
    font-size: 20px;
    // 鼠标样式
    cursor: pointer;
    border: 1px solid #eee;
    background: #ddd;
  }

  .right_icon:hover {
    color: #e1251b;
  }
}
</style>

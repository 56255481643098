<template>
  <div class="High_evel">
    <!-- 回到顶部 -->
    <el-backtop :visibility-height="100"> </el-backtop>

    <Header></Header>
    <!-- 路由组件的出口 -->
    <router-view></router-view>
    <!-- 中间分隔层 -->
    <div class="separate"></div>
    <!-- 底部分栏二维码区 -->
    <FooterColumns></FooterColumns>
    <!-- 底部备案 -->
    <NewFooter></NewFooter>
  </div>
</template>

<script>
// 引入header
import Header from "./components/Header.vue";
import FooterColumns from "@/pagaes/Home/FooterColumns/index.vue";
import NewFooter from "@/components/NewFooter/index.vue";
export default {
  name: "App",
  components: { Header, FooterColumns, NewFooter },
  data() {
    return {};
  },
  mounted() {
    window.addEventListener("setItemEvent", (e) => {
      console.log("setItemEvent", e);
    });
  },
};
</script>

<style>
#nprogress .bar {
  /* //这里可以随便写颜色 */
  background: #cc3030 !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  background-color: #d4d3d3;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

.separate {
  height: 40px;
  background-color: #f2f2f2;
}
.width_1200_auto {
  width: 1200px;
  margin: 0 auto;
}
</style>

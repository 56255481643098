<template>
  <div
    class="footer"
    :class="{ isLogin: isLogin }"
  >
    <div class="footer-box">
      <!-- 底部分栏 -->
      <div class="footer-columns">
        <p>关于我们</p>
        <span class="line"></span>
        <p>联系我们</p>
        <span class="line"></span>
        <p>法律声明</p>
        <span class="line"></span>
        <p>隐私政策</p>
        <span class="line"></span>
        <p>营业执照</p>
      </div>
      <!-- 底部备案 -->
      <div class="footer-filing">
        <p>© 2022-现在 广州瑞仁源科技有限公司</p>
<!--        <span class="line"></span>-->
<!--        <p>增值电信业务经营许可证：粤B2-20191630</p>-->
        <span class="line"></span>
        <p>粤ICP备2023119681号</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isLogin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>

.footer {
  padding: 5px 0;
  background: #e0e2e4;
  .footer-box {
    width: 1200px;
    margin: 0 auto;
    .footer-columns {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        padding: 5px 18px;
        font-size: 12px;
        color: #666;
        cursor: pointer;
      }
      p:hover {
        color: #cc3030;
      }
    }

    .footer-filing {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        padding: 5px 18px;
        font-size: 12px;
        color: #666;
        cursor: default;
      }
    }
    .line {
      height: 10px;
      border-right: 2px solid #969798;
    }
  }
}
.isLogin {
  background: #fff;
}
</style>
